export default {
    logo: {
        auth: '/media/logos/logo-laser.png',
        splash: '/media/logos/logo-laser.png',
        navbar: '/media/logos/logo-laser.png'
    },
    background: '/media/bg/sc-home1-bg.png',
    brandName: 'Laser Ticketing',
    brandSite: '#',
    brandingFooter: true,
    allowOrganisationSelection: true,
    allowImpersonation: true,
    timeTracking: false,
    allowFieldHelpText: false,
    dateFormatDayLightSaving: false,
    landingPath: '/dashboard',
    color: {
        primary: '#7E8299',
        secondary: '#3B4F64',
        loginSide: '#7E8299'
    },
    applicationAreaID: 1,
    metronic: {
        quickPanel: false,
        quickUser: true,
        quickActions: false,
        menu: false,
        aside: true,
        search: false,
        notifications: true,
        subheader: false
    },
    services: {
        endpoint: process.env.REACT_APP_DATA_API_URL!,
        gql: process.env.REACT_APP_GRAPH_API_URL!,
        env: process.env.REACT_APP_ENV!,
        onesignal: process.env.REACT_APP_ONE_SIGNAL_ID!,
        getaddress: process.env.REACT_APP_GET_ADDRESS_API_KEY,
    },
    token: {
        name: 'laserADTK',
        expireday: 1, // 1 day
        domain: '',
        expireMessage: 'To protect your data, you have been logged out. Please login again to continue.'
    },
    notification: {
        soundEffectEnable: true,
        pathSoundEffect: '/media/audio/mixkit-attention-bell-ding.wav'
    },
    toaster: {
        soundEffectEnable: false,
        pathSoundEffectIn: '/media/audio/mixkit-message-pop-alert.mp3',
        pathSoundEffectOut: '/media/audio/mixkit-long-pop.wav'
    },
    messages: {
        simultaneousPreventionMessage: "You have been logged out because your User Account has logged in elsewhere.",
        error: process.env.REACT_APP_ENV === 'production' ? 'Something went wrong.' : 'There is an issue with the endpoint.',
        fail: 'There are errors with your entry. Please check and try again.'
    }, 
    menuIcons: {
    },
    menuPopups: {
    }
}