import { gql } from "@apollo/client";

export type DropdownCommonType<P = Record<string, any>> = {
  value: number;
  label: string;
} & P

export type DropdownQueryType<P = DropdownCommonType> = {
  dropdown: {
    items: P[];
  };
}

export const AvailableTagsDropdownQuery = gql`
  query AvailableTagsDropdownQuery($where: AvailableTagFilterInput) {
    dropdown: AvailableTags(where: $where) {
      items {
        value: Tag
        label: Tag
        TagColour
        ReversedText
      }
    }
  }
`;

export const AvailableTagGroupsDropdownQuery = gql`
query AvailableTagGroupsDropdownQuery(
    $where: AvailableTagGroupFilterInput
) {
    dropdown: AvailableTagGroups(where: $where) {
        totalCount
        items {
            value: ID
            label: Description
        }
    }
}
`;

export const FontAwesomeIconsDropdownQuery = gql`
query FontAwesomeIconsDropdownQuery($where: FontAwesomeIconFilterInput) {
    dropdown: FontAwesomeIcons(where: $where) {
      items {
        value: CSSClass
        label: Description
      }
    }
  }
`;

export const ColourPalettesDropdownQuery = gql`
query ColourPalettesDropdownQuery($where: ColourPaletteFilterInput) {
    dropdown: ColourPalettes(where: $where) {
      items {
        value: HexCode
        label: Description
      }
    }
  }
`;

export const OrganisationsDropdownQuery = gql`
query OrganisationsDropdownQuery($where: OrganisationFilterInput) {
    dropdown: Organisations(where: $where) {
      items {
        value: ID
        label: CompanyName
      }
    }
  }
`;

export const OrganisationsByTypeDropdownQuery = gql`
query OrganisationsByTypeDropdownQuery(
  $where: OrganisationFilterInput, 
  $organisationTypeID: Int!
  ) {
    dropdown: OrganisationsByType(
      where: $where, 
      OrganisationTypeID: $organisationTypeID
    ) {
      items {
        value: ID
        label: CompanyName
      }
    }
  }
`;

export const SentryProjectDropdownQuery = gql`
query SentryProjectDropdownQuery($where: SentryProjectFilterInput) {
    dropdown: SentryProjects(where: $where) {
      items {
        value: ID
        label: ProjectName
      }
    }
  }
`;

export const OrganisationTypesDropdownQuery = gql`
  query OrganisationTypesDropdownQuery{
    dropdown: OrganisationTypes {
      items {
        value: ID
        label: Description
      }
    }
  }
`;


export const ParameterTypesDropdownQuery = gql`
  query ParameterTypesDropdownQuery{
    dropdown: ParameterTypes {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const PermissionsDropdownQuery = gql`
  query PermissionsDropdownQuery($where: PermissionFilterInput) {
    dropdown: Permissions(where: $where) {
      items {
        value: ID
        label: Title
      }
    }
  }
`;

export const JobRolesDropdownQuery = gql`
  query JobRolesDropdownQuery{
    dropdown: JobRoles(order: { Description: ASC }) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const UserTypesDropdownQuery = gql`
  query UserTypesDropdownQuery{
    dropdown: UserTypes {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const CountriesDropdownQuery = gql`
  query CountriesDropdownQuery{
    dropdown: Countries {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const MobileCountriesDropdownQuery = gql`
  query MobileCountriesDropdownQuery($order: [CountrySortInput!]) {
    dropdown: Countries(order: $order) {
      items {
        value: ID
        label: PhonePrefix
      }
    }
  }
`;

export const TitlesDropdownQuery = gql`
  query TitlesDropdownQuery{
    dropdown: Titles {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const EmailTemplatesDropdownQuery = gql`
  query EmailTemplatesDropdownQuery($where: EmailTemplateFilterInput) {
    dropdown: EmailTemplates(
      where: $where
    ) {
      items {
        value: ID
        label: TemplateName
      }
    }
  }
`;

export const EmailTemplatePresetsDropdownQuery = gql`
  query($where: EmailTemplateFilterInput) {
    dropdown: EmailTemplates(
      where: $where
    ) {
      items {
        value: ID
        label: TemplateName
        PreviewText
        EmailBody
        EmailSubject
        PrivateToUserID
        EmailTemplateAttachments {
          Filename
          OriginalFileName
        }
      }
    }
  }
`;

export const ApplicationAreasDropdownQuery = gql`
  query ApplicationAreasDropdownQuery{
    dropdown: ApplicationAreas(order: { Description: ASC }) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const UsersDropdownQuery = gql`
  query UsersDropdownQuery($where: UserFilterInput) {
    dropdown: Users(where: $where, order: { Firstname: ASC }) {
      items {
        ID
        Firstname
        Surname
      }
    }
  }
`;

export const CompanyUsersDropdownQuery = gql`
  query CompanyUsersDropdownQuery($id: Int!, $where: UserFilterInput) {
    dropdown: UsersInOrganisation(iD: $id, where: $where, order: { Firstname: ASC }) {
      items {
        ID
        Firstname
        Surname
      }
    }
  }
`;

export const UsersByUserTypesDropdownQuery = gql`
  query UsersByUserTypesDropdownQuery($userTypeIDs: [Int!], $where: UserFilterInput) {
    dropdown: UsersByUserTypes(userTypeIDs: $userTypeIDs, where: $where, order: { Firstname: ASC }) {
      items {
        ID
        Firstname
        Surname
      }
    }
  }
`;

export const AccountSystemContactsDropdownQuery = gql`
  query AccountSystemContactsDropdownQuery($where: AccountSystemContactFilterInput) {  
    dropdown: AccountSystemContacts(where: $where) {
      items {
        value: ID
        label: Name
      }
    }
  }
`;

export const InvoicingModesDropdownQuery = gql`
  query InvoicingModesDropdownQuery($where: InvoicingModeFilterInput) {  
    dropdown: InvoicingModes(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const BudgetAccountCodeTypesDropdownQuery = gql`
  query BudgetAccountCodeTypesDropdownQuery($where: AccountCodeTypeFilterInput) {  
    dropdown: AccountCodeTypes(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const BudgetAccountCodesDropdownQuery = gql`
  query BudgetAccountCodesDropdownQuery($where: AccountCodeFilterInput) {  
    dropdown: AccountCodes(where: $where) {
      items {
        value: ID
        label: Description
        Code
      }
    }
  }
`;

export const BudgetVersionDropdownQuery = gql`
  query BudgetVersionDropdownQuery{  
    dropdown: BudgetVersions {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const UsersInOrganisationImpersonationDropdownQuery = gql`
  query UsersInOrganisationImpersonationDropdownQuery(
    $skip: Int,
    $take: Int,
    $id: Int!,
    $where: UserFilterInput,
    $order: [UserSortInput!]
    ) {
    dropdown: UsersInOrganisation(
      skip: $skip,
      take: $take,
      iD: $id,
      where: $where,
      order: $order
    ) {
      totalCount
      items {
        value: ID
      }
    }
  }
`;

export const UsersInOrganisationDropdownQuery = gql`
  query UsersInOrganisationDropdownQuery(
    $skip: Int,
    $take: Int,
    $id: Int!,
    $where: UserFilterInput,
    $order: [UserSortInput!]
    ) {
    dropdown: UsersInOrganisation(
      skip: $skip,
      take: $take,
      iD: $id,
      where: $where,
      order: $order
    ) {
      items {
        ID
        Firstname
        Surname
      }
    }
  }
`;

export const ProjectsDropdownQuery = gql`
  query ProjectsDropdownQuery($where: ProjectFilterInput) {  
    dropdown: Projects(where: $where) {
      items {
        value: ID
        label: ProjectName
      }
    }
  }
`;

export const TasksDropdownQuery = gql`
  query TasksDropdownQuery($where: TaskFilterInput) {  
    dropdown: Tasks(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const AutoCommActionTypesDropdownQuery = gql`
  query AutoCommActionTypesDropdownQuery($where: AutoCommActionTypeFilterInput) {  
    dropdown: AutoCommActionTypes(where: $where) {
      items {
        value: ID
        label: Description
        ParameterRequired
      }
    }
  }
`;

export const AutoCommTriggersDropdownQuery = gql`
  query AutoCommTriggersDropdownQuery($where: AutoCommTriggerFilterInput) {  
    dropdown: AutoCommTriggers(where: $where) {
      items {
        value: ID
        label: Description
        AutoCommTriggerParams {
          value: ID
          label: Description
          DataType
          Required
        }
      }
    }
  }
`;

export const GendersDropdownQuery = gql`
  query($where: GenderFilterInput) {  
    dropdown: Genders(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const ChatGPTContextsDropdownQuery= gql`
query ChatGPTContextsDropdownQuery($where: ChatGPTContextFilterInput) {  
  dropdown: ChatGPTContexts(where: $where) {
    items {
      value: ID
      label: Description
    }
  }
}`;

export const ChatGPTSubContextsDropdownQuery= gql`
query ChatGPTSubContextsDropdownQuery($where: ChatGPTSubContextFilterInput) {  
  dropdown: ChatGPTSubContexts(where: $where) {
    items {
      value: ID
      label: Description
    }
  }
}`;

export const ChatGPTTonesDropdownQuery= gql`
query ChatGPTTonesDropdownQuery($where: ChatGPTToneFilterInput) {  
  dropdown: ChatGPTTones(where: $where) {
    items {
      value: ID
      label: Description
    }
  }
}`;
/* CUSTOMS - START */

export const OrganisationStatusDropdownQuery = gql`
  query($where: OrganisationStatusFilterInput) {  
    dropdown: OrganisationStatuss(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const OrganisationGroupsDropdownQuery = gql`
  query($where: OrganisationGroupFilterInput) {  
    dropdown: OrganisationGroups(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

export const SoftwareDropdownQuery = gql`
  query($where: SoftwareFilterInput) {  
    dropdown: Softwares(where: $where) {
      items {
        value: ID
        label: Description
      }
    }
  }
`;

/* CUSTOMS - END */