import React, { useMemo } from 'react'
import Tab from 'react-bootstrap/Tab'
import Button from '@whitecobalt/tungsten/Button'
import Toaster from '@whitecobalt/tungsten/Toaster'
import FileField from '@whitecobalt/tungsten/Fields/FileField'
import FormManager from '@whitecobalt/tungsten/FormManager'
import SelectDropdown from '@whitecobalt/tungsten/Fields/SelectDropdown'
import { useSession, SessionActionType } from '@whitecobalt/tungsten/Session'
import { useAuthAPI } from '@whitecobalt/tungsten/hooks/useAuthAPI'
import { MobileCountriesDropdownQuery, TitlesDropdownQuery } from '@services/query/DropdownQueries'
import branding from '@config/branding'
import DataList from '@whitecobalt/tungsten/Fields/DataList'
import ChangeEmailAction from './ChangeEmailAction'
import OneSignalSubscription from "./OneSignalSubscription"
import { FormEvent, useForm } from "formydable"
import { useUserPreventClose } from "../../useUserPreventClose"
import EmailSignature from "./EmailSignature"

const UserOffcanvasProfile: React.FunctionComponent = () => {
    const [{ user }, dispatch] = useSession()
    const request = useAuthAPI('/api/SaveUser')
    const form = useForm()
    useUserPreventClose(form.dirty)
    const handleSubmit = (event: FormEvent) => {
        const FORM_DATA = event.json()

        const payload = {
            original: {
                "id": user?.id,
                "photo": user?.photo,
            },
            new: {
                "id": user?.id,
                "title": FORM_DATA.title,
                "firstname": FORM_DATA.firstname,
                "surname": FORM_DATA.surname,
                "emailAddress": user?.emailAddress,
                "jobTitle": FORM_DATA.jobTitle,
                "phoneNumber": FORM_DATA.phoneNumber,
                "mobileNumber": FORM_DATA.mobileNumber,
                "mobileCountryID": FORM_DATA.mobileCountryID || 0,
                "useImperialUnits": FORM_DATA.useImperialUnits,
                "photo": FORM_DATA.photo ? FORM_DATA.photo[0] : undefined
            },
        }

        const toast = Toaster(`Updating Profile`)
        request.call({ data: payload }).then((response) => {
            if (response.data.success) {
                toast.success(`Successfully updated Profile`)
                dispatch({
                    type: SessionActionType.SET_USER,
                    payload: { ...user, ...payload.new }
                })
                event.setDirty(false)
            } else {
                FormManager.setBackendValidation(event, response.data.errors, {
                    'Firstname': 'firstname',
                    'Surname': 'surname',
                    'EmailAddress': 'emailAddress',
                    'EmergencyEmail': 'emailAddress'
                })

                toast.fail(response?.data?.message || branding.messages.fail)
            }
        })
        .catch((error) => {
            const errorMessage = error?.response?.data?.message || branding.messages.error
            toast.error(errorMessage);
        })

    }

    const timeZoneString = useMemo(() => {
        const now = new Date();
        const utcOffsetMinutes = now.getTimezoneOffset();
        const sign = utcOffsetMinutes < 0 ? '+' : '-';
        const hoursOffset = Math.abs(Math.floor(utcOffsetMinutes / 60));
        const minutesOffset = Math.abs(utcOffsetMinutes % 60);

        return `${sign}${String(hoursOffset).padStart(2, '0')}:${String(minutesOffset).padStart(2, '0')}`
    }, []);

    const showEmailSignature = user?.adminUser && 'emailSignature' in user!

    return (
        <Tab.Pane eventKey="profile">
            {branding.services.onesignal && (
                <OneSignalSubscription />
            )}
            {showEmailSignature && (
                <EmailSignature />
            )}
            {branding.services.onesignal || showEmailSignature && (
                <div className="separator separator-dashed mt-8 mb-5" />
            )}
            <FormManager.Core value={form} onSubmit={handleSubmit}>
                <FormManager.Input
                    name="title"
                    label="Title"
                    value={user?.title}
                    list="titles"
                    data-lpignore="true"
                    />
                <DataList.Graph gql={TitlesDropdownQuery} fetchPolicy="cache-first" queryKey="dropdown" optionKey="label" id="titles" />
                <FormManager.Input
                    name="firstname"
                    label="First Name"
                    value={user?.firstname}
                    maxLength={100}
                />
                <FormManager.Input
                    name="surname"
                    label="Surname"
                    value={user?.surname}
                    maxLength={100}
                />
                <FormManager.Input
                    name="emailAddress"
                    label="Email Address"
                    value={user?.emailAddress}
                    disabled
                    maxLength={100}
                />
                <ChangeEmailAction />
                <FormManager.Input
                    name="jobTitle"
                    label="Job Title"
                    value={user?.jobTitle || ""}
                    maxLength={100}
                />
                <FormManager.Input
                    name="phoneNumber"
                    label="Phone Number"
                    value={user?.phoneNumber}
                />
                <FormManager.Input
                    prepend={(
                        <FormManager.Input
                            as={SelectDropdown.Graph}
                            gql={MobileCountriesDropdownQuery}
                            fetchPolicy="cache-first"
                            variables={{
                                order: { ID: "ASC" }
                            }}
                            name="mobileCountryID"
                            stripped
                            clearable={false}
                            value={user?.mobileCountryID || 1}
                            manageOptions={(item: any) => ({ ...item, label: `+${item.label}` })}
                        />
                    )}
                    // as={MaskField}
                    // focusSelectAll
                    // masked="9999 999999"
                    mutateOnChange={(event: any) => {
                        return {
                            name: event.target.name,
                            value: event.target.value.replace(/[\D]/g, '')
                        }
                    }}
                    maxLength={11}
                    name="mobileNumber"
                    label="Mobile Number"
                    value={(user?.mobileNumber || '').length === 11 ? user?.mobileNumber : (user?.mobileNumber || '').length === 10 ? user?.mobileNumber!.substr(0, 4) + " " + user?.mobileNumber!.substr(4) : undefined}
                />
                <FormManager.Input
                    name="timeZone"
                    disabled
                    label="Timezone"
                    value={`${Intl.DateTimeFormat().resolvedOptions().timeZone} (UTC ${timeZoneString})`}
                    description="Timezone you are in." 
                />
                <FormManager.Input
                    as={FileField}
                    name="photo"
                    label="Photo"
                    value={user?.photo}
                    previews={user?.photo}
                    wrappedValueOnArray
                    defaultValue={[]}
                    accept="image/*"
                    placeholder="A photo of yourself here"    
                />
                <div className="d-flex justify-content-end">
                    <Button type="submit" loading={request.loading} variant="primary">
                        Update
                    </Button>
                </div>
            </FormManager.Core>
        </Tab.Pane>
    )
}

export default UserOffcanvasProfile