import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import ConditionalRoute from "@whitecobalt/tungsten/ConditionalRoute";
import ErrorBoundary from "@whitecobalt/tungsten/ErrorBoundary";
import Loader from "@whitecobalt/tungsten/Loader";
import { useSession, SessionActionType } from "@whitecobalt/tungsten/Session";
import branding from "@config/branding";
import AuthRoutes from "./pages/Auth";
import { ErrorPage } from "./pages/ErrorPage";
import { ContentRoute, Layout } from "@metronic/layout";
import CustomPublic from "./pages/CustomPublic";
import {
    Users,
    RegisteredUsers,
    SentEmails,
    SentSMS,
	SystemSettings,
	Reports,
	Widgets,
	Dashboard,
	Customers,
	Invoices,
	PermissionPresets,
	DocumentTemplates,
	EmailTemplates,
	SMSTemplates,
	Bills,
	PaymentRuns,
	PaymentAccounts,
	TimeLogs,
	Budget,
	MyCompany,
	BackgroundProcesses,
	DisconnectedProcesses,
	EmailResponses,
	CRMGroups,
	CRMIntroducers,
	CRM,
	EmailCampaigns,
	EmailInbox,
	AutoEmailCampaigns,
	AvailableTags
} from './pages'
import CacheGQL from "@components/CacheGQL";

const MetronicRoute: any = ContentRoute

const Routes = () => {
	const [{ loading, user, others }, dispatch] = useSession();
	const hasAuthenthication = !!(user && (branding.allowOrganisationSelection ? others.hasSelectedOrganisation : true))

	const handleRedirect = (redirected: boolean, props: any) => {
		if (redirected) {
			dispatch({
				type: SessionActionType.SET_REDIRECT,
				payload: props.location.pathname
			})
		}
	}

	if(loading) {
		return (
			<Loader active hasSpinner={false} className="kt-splash-screen">
				<div className="mb-4"  data-env-impose="true">
					<img src={branding.logo.splash} style={{maxWidth: 250}}/>
				</div>
				<Spinner animation="border" />
			</Loader>
		)
	}

	return (
		<Switch>
			<Route path="/auth">
				<AuthRoutes />
			</Route>
			<Route path="/public">
				<CustomPublic />
			</Route>
			<ConditionalRoute
				condition={hasAuthenthication}
				onRedirect={handleRedirect}
				redirectTo='/auth/login'
				path="/">
				<CacheGQL>
					<Layout>
						<ErrorBoundary>
							<Suspense fallback={(<Loader active />)}>
								<Switch>
									<MetronicRoute path="/registered-users" component={RegisteredUsers} />
									<MetronicRoute path="/emails" component={SentEmails} />
									<MetronicRoute path="/sms" component={SentSMS} />
									<MetronicRoute path="/syssettings" component={SystemSettings} />
									<MetronicRoute path="/reports" component={Reports} />
									<MetronicRoute path="/widgets" component={Widgets} />
									<MetronicRoute path="/dashboard" component={Dashboard} />
									<MetronicRoute path="/admin/users" component={Users} />
									<MetronicRoute path="/admin/invoices" component={Invoices} />
									<MetronicRoute path="/admin/bills" component={Bills} />
									<MetronicRoute path="/admin/permission-presets" component={PermissionPresets} />
									<MetronicRoute path="/admin/document-templates" component={DocumentTemplates} />
									<MetronicRoute path="/admin/email-templates" component={EmailTemplates} />
									<MetronicRoute path="/admin/sms-templates" component={SMSTemplates} />
									<MetronicRoute path="/admin/payment-accounts" component={PaymentAccounts} />
									<MetronicRoute path="/admin/payment-runs" component={PaymentRuns} />
									<MetronicRoute path="/time-logs" component={TimeLogs} />
									<MetronicRoute path="/budget/actual" component={Budget} />
									<MetronicRoute path="/budget" component={Budget} />
									<MetronicRoute path="/admin/background-process" component={BackgroundProcesses} />
									<MetronicRoute path="/admin/disconnected-jobs" component={DisconnectedProcesses} />
									<MetronicRoute path="/admin/available-tags" component={AvailableTags} />
									<MetronicRoute path="/inbox" component={EmailInbox} />
									{/** Custom Pages - Start */}
										<MetronicRoute path="/customers" component={Customers} />
										<MetronicRoute path="/my-company" component={MyCompany} />
										<MetronicRoute path="/crm/autoemailresponses" component={EmailResponses} />
										<MetronicRoute path="/crm/groups" component={CRMGroups} />
										<MetronicRoute path="/crm/introducers" component={CRMIntroducers} />
										<MetronicRoute path="/crm/companies" component={CRM} />
										<MetronicRoute path="/crm/autoemailcampaigns" component={AutoEmailCampaigns} />
										<MetronicRoute path="/email-campaign" component={EmailCampaigns} />
									{/** Custom Pages - End */}
									<Redirect path="/" exact to={branding.landingPath} />
									<Route path="*" component={ErrorPage}/>
								</Switch>
							</Suspense>
						</ErrorBoundary>
					</Layout>
				</CacheGQL>
			</ConditionalRoute>
			{/* <Route path="*" component={ErrorPage}/> */}
		</Switch>
	);
}

export default Routes